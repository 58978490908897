import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import tw, { css } from "twin.macro";

import {
	Body,
	ButtonBeige,
	H3,
} from "../styles/AppStyles.styles.tw";

import ScheduleImage from "../images/photos/river.jpg";
import BackgroundPattern from "../images/patterns/Teal_Pattern.png";

const backgroundPattern = css`
	background-image: url(${BackgroundPattern});
`;

function ScheduleBanner(): JSX.Element {
	return (
		<StaticQuery
			query={graphql`
				query {
					sanityScheduleSection {
						body
						buttonText
						buttonURL
						header
						image {
							asset {
								gatsbyImageData
								url
							}
						}
					}
				}
			`}
			render={(data) => (
				<section
					css={[
						tw`flex items-center justify-center py-32 text-center bg-center bg-no-repeat bg-cover lg:py-48 text-beige`,
						css`
							background-image: url(${data
								.sanityScheduleSection.image.asset.url});
						`,
					]}
				>
					<div
						css={[
							tw`w-10/12 max-w-4xl p-8 space-y-4 lg:py-16`,
							backgroundPattern,
						]}
					>
						<H3>{data.sanityScheduleSection.header}</H3>
						<Body>{data.sanityScheduleSection.body}</Body>
						<div>
							<a href={data.sanityScheduleSection.buttonURL}>
								<ButtonBeige>
									{data.sanityScheduleSection.buttonText}
								</ButtonBeige>
							</a>
						</div>
					</div>
				</section>
			)}
		/>
	);
}

export default ScheduleBanner;
