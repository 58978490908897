/* eslint-disable react/no-unknown-property */
import tw, { css } from "twin.macro";
import { graphql } from "gatsby";
import {
	GatsbyImage,
	getImage,
	IGatsbyImageData,
} from "gatsby-plugin-image";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ScheduleBanner from "../components/ScheduleBanner";
import {
	AppStyles,
	Body,
	H2,
	TextLink,
} from "../styles/AppStyles.styles.tw";

import BackgroundPattern from "../images/patterns/Green_Pattern.png";

function Amenities({ data }: any): JSX.Element {
	const page = data.sanityAmenities;

	const amenitiesImage = css`
		background-image: url(${page.heroImage
			.asset.url});
		@media screen and (min-width: 768px) {
			min-height: 650px;
		}
	`;

	const backgroundPattern = css`
		background-image: url(${BackgroundPattern});
	`;

	return (
		<Layout title="amenities">
			<SEO
				title={page.pageTitle}
				description={page.pageDescription}
				keywords={page.keywords.join(", ")}
			/>
			<AppStyles>
				<section
					css={[
						tw`flex flex-col items-center justify-center px-4 py-32 text-center text-white bg-center bg-no-repeat bg-cover lg:px-0 2xl:py-48`,
						amenitiesImage,
					]}
				>
					<div>
						<H2
							css={[
								tw`rounded-lg lg:px-2`,
								css`
									text-shadow: 0 2px 3px rgba(0, 0, 0, 75%);
									backdrop-filter: blur(2px);
								`,
							]}
						>
							{page.heroHeader.split(".").map(
								(text: string, i: number) =>
									text !== "" && (
										<span tw="block" key={i}>
											{text}.
										</span>
									)
							)}
						</H2>
					</div>
				</section>
				<section tw="bg-beige-light py-8">
					<div tw="container mx-auto flex justify-center items-center flex-wrap">
						<div tw="lg:mx-8">
							<GatsbyImage
								image={
									getImage(
										page.section1Image.asset
											.gatsbyImageData
									) as IGatsbyImageData
								}
								alt={page.section1Image.alt}
								tw="w-full h-full object-cover object-center"
							/>
						</div>
						<Body tw="px-4 py-4 lg:px-0 lg:mx-8">
							{page.section1Body}
						</Body>
					</div>
				</section>
				<section
					css={[
						tw`py-8 bg-center bg-cover bg-pine lg:py-16`,
						backgroundPattern,
					]}
				>
					<div tw="container mx-auto grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 justify-items-center items-start gap-4 px-4 lg:px-0">
						<GatsbyImage
							image={
								getImage(
									page.amenitiesImage1.asset
										.gatsbyImageData
								) as IGatsbyImageData
							}
							alt={page.amenitiesImage1.alt}
							tw="w-full h-96 md:w-96"
						/>
						<div tw="bg-pine w-full lg:w-96 mx-auto border border-beige-dark text-white lg:col-start-2 lg:row-start-1 row-span-2 py-4">
							<h4 tw="uppercase text-rust font-bold  pb-4 px-8 mb-4 border-b border-beige-dark">
								{page.amenitiesHeader2}
							</h4>
							<div tw="space-y-8 px-8 pb-4">
								{page.amenitiesList2.map(
									(item: string, i: number) => (
										<Body key={i}>{item}</Body>
									)
								)}
							</div>
						</div>
						<div tw="bg-pine w-full lg:w-96 mx-auto border border-beige-dark text-white col-start-1 py-4">
							<h4 tw="uppercase text-rust font-bold  pb-4 px-8 mb-4 border-b border-beige-dark">
								{page.amenitiesHeader1}
							</h4>
							<div tw="space-y-8 px-8 pb-4">
								{page.amenitiesList1.map(
									(item: string, i: number) => (
										<Body key={i}>{item}</Body>
									)
								)}
							</div>
						</div>
						<div tw="bg-pine w-full lg:w-96 mx-auto border border-beige-dark text-white lg:row-start-3 xl:row-start-2 xl:col-start-3 py-4">
							<h4 tw="uppercase text-rust font-bold  pb-4 px-8 mb-4 border-b border-beige-dark">
								{page.amenitiesHeader3}
							</h4>
							<div tw="space-y-8 px-8 pb-4">
								{page.amenitiesList3.map(
									(item: string, i: number) => (
										<Body key={i}>{item}</Body>
									)
								)}
							</div>
						</div>
						<GatsbyImage
							image={
								getImage(
									page.amenitiesImage3.asset
										.gatsbyImageData
								) as IGatsbyImageData
							}
							alt={page.amenitiesImage3.alt}
							tw="w-full h-96 md:w-96 lg:col-start-2 xl:col-start-3 xl:row-start-1"
						/>
					</div>
				</section>
				<section tw="bg-beige-light py-8 text-center">
					<div tw="container mx-auto px-4 lg:px-0 tracking-wider">
						<Body tw="font-bold">
							{page.section2Body}
						</Body>
						<TextLink
							href={page.section2LinkURL}
						>
							{page.section2LinkText}
						</TextLink>
					</div>
				</section>
				<ScheduleBanner />
			</AppStyles>
		</Layout>
	);
}

export default Amenities;

export const query = graphql`
	{
		sanityAmenities {
			heroHeader
			heroImage {
				alt
				asset {
					url
					gatsbyImageData
				}
			}
			pageTitle
			pageDescription
			keywords
			section1Body
			section1Image {
				asset {
					url
					gatsbyImageData
				}
			}
			section2Body
			section2LinkText
			section2LinkURL
			amenitiesHeader1
			amenitiesHeader2
			amenitiesHeader3
			amenitiesImage1 {
				asset {
					gatsbyImageData
					url
				}
			}
			amenitiesImage3 {
				alt
				asset {
					gatsbyImageData
					url
				}
			}
			amenitiesList1
			amenitiesList2
			amenitiesList3
		}
	}
`;
